// 3D FBM noise https://shadertoy.com/view/lss3zr
export const fbm = /* glsl */ `
  mat3 m = mat3(0.00, 0.80, 0.60, -0.80, 0.36, -0.48, -0.60, -0.48, 0.64);
  float hash(float n, float seed) {
    return fract(sin(n + seed) * 43758.5453);
}

  float noise(vec3 x, float uTime, float seed) {
    x += uTime / 12.0; // Add time to the position
    vec3 p = floor(x);
    vec3 f = fract(x);

    f = f * f * (3.0 - 2.0 * f);

    float n = p.x + p.y * 57.0 + 113.0 * p.z;

    float res = mix(mix(mix(hash(n + 0.0, seed), hash(n + 1.0, seed), f.x),
                        mix(hash(n + 57.0, seed), hash(n + 58.0, seed), f.x), f.y),
                        mix(mix(hash(n + 113.0, seed), hash(n + 114.0, seed), f.x),
                        mix(hash(n + 170.0, seed), hash(n + 171.0, seed), f.x), f.y), f.z);
    return res;
  }

  float fbm(vec3 p, float uTime, float uSeed) {
    float f = 0.0;
    f += 0.5000 * noise(p, uTime, uSeed); p = m * p * 2.02;
    f += 0.2500 * noise(p, uTime, uSeed); p = m * p * 2.03;
    f += 0.12500 * noise(p, uTime, uSeed); p = m * p * 2.01;
    f += 0.06250 * noise(p, uTime, uSeed); p = m * p * 2.04;
    f += 0.03983 * noise(p, uTime, uSeed);
    return f;
  }
`;

export const fragmentShader = /* glsl */ `
  ${fbm}

  uniform vec3 uCloudSize;
  uniform vec3 uSunPosition;
  uniform vec3 uCameraPosition;
  uniform vec3 uCloudColor;
  uniform vec3 uSkyColor;
  uniform float uCloudSteps;
  uniform float uShadowSteps;
  uniform float uCloudLength;
  uniform float uShadowLength;
  uniform vec2 uResolution;
  uniform mat4 projectionMatrixInverse;
  uniform mat4 viewMatrixInverse;
  uniform float uTime;
  uniform bool uRegress;
  uniform float uSeed;

  float cloudDepth(vec3 position) {
    float ellipse = 1.0 - length(position * uCloudSize);
    float cloud = ellipse + fbm(position, uTime, uSeed) * 2.2;

    return min(max(0.0, cloud), 1.0);
  }

  // https://shaderbits.com/blog/creating-volumetric-ray-marcher
  vec4 cloudMarch(float jitter, vec3 position, vec3 ray, vec2 uv) {
    float stepLength = uCloudLength / uCloudSteps;
    float shadowStepLength = uShadowLength / uShadowSteps;

    vec3 lightDirection = normalize(uSunPosition);
    vec3 cloudPosition = position + ray * jitter * stepLength;

    vec4 color = vec4(0.0, 0.0, 0.0, 1.0);

    for (float i = 0.0; i < uCloudSteps; i++) {
      if (color.a < 0.1) break;

      float depth = cloudDepth(cloudPosition);
      if (depth > 0.001) {
        vec3 lightPosition = cloudPosition + lightDirection * jitter * shadowStepLength;

        float shadow = 0.0;
        for (float s = 0.0; s < uShadowSteps; s++) {
          lightPosition += lightDirection * shadowStepLength;
          shadow += cloudDepth(lightPosition);
        }
        shadow = exp((-shadow / uShadowSteps) * 3.0);

        float density = clamp((depth / uCloudSteps) * 20.0, 0.0, 1.0);
        color.rgb += vec3(shadow * density) * uCloudColor * color.a;
        color.a *= 1.0 - density;

        // Modify the sky color to be 10% brighter from top to bottom
        color.rgb += density * uSkyColor * color.a * (1.0 + 0.1 * (1.0 - uv.y));
      }

      cloudPosition += ray * stepLength;
    }

    return color;
  }

  void main() {
    vec2 uv = gl_FragCoord.xy / uResolution;
    vec4 point = projectionMatrixInverse * vec4(uv * 2.0 - 1.0, -1.0, 1.0);
    vec3 ray = (viewMatrixInverse * vec4(point.xyz, 0)).xyz;

    float jitter = uRegress ? hash(uv.x + uv.y * 50.0 + uTime, uSeed) : 0.0;

    vec4 color = cloudMarch(jitter, uCameraPosition, ray, uv);
    gl_FragColor = vec4(color.rgb + uSkyColor * color.a * (1.0 + 0.1 * (1.0 - uv.y)), 1.0);
  }
`;
