import * as THREE from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import Cloud from "./Cloud";
import Stats from 'stats.js';

function init(skyColor, cloudColor, showStats) {
    const renderer = new THREE.WebGLRenderer({
        antialias: true,
        powerPreference: "high-performance"
    });
    document.body.appendChild(renderer.domElement);

    let stats;
    if(showStats) {
        stats = new Stats();
        stats.showPanel(0);
        document.body.appendChild(stats.dom);
    } else {
        stats = {
            begin: () => {},
            end: () => {},
        }
    }

    const camera = new THREE.PerspectiveCamera(70);
    camera.position.set(7.575289619048468, 1.2729036411219907, -1);
    camera.lookAt(0, 0, 0);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.rotateSpeed = 0.0;
    controls.panSpeed = 0.0;
    controls.zoomSpeed = 0.0;
    controls.keyPanSpeed = 0.0;

    const cloud = new Cloud({
        cloudSize: new THREE.Vector3(1.2, 1.6, 0.6),
        sunPosition: new THREE.Vector3(1.0, 4.0, 1.0),
        cloudColor: new THREE.Color(cloudColor),
        skyColor: new THREE.Color(skyColor),
        cloudSteps: 48,
        shadowSteps: 24,
        cloudLength: 8,
        shadowLength: 0.8,
        noise: true,
        // values < 10_000 give glitchy clouds for some reason
        seed: Math.random() * 100000
    });

    const handleResize = () => {
        const dpr = Math.min(window.devicePixelRatio, 2);
        renderer.setPixelRatio(dpr);
        renderer.setSize(window.innerWidth, window.innerHeight);
        cloud.setSize(window.innerWidth * dpr, window.innerHeight * dpr);
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        cloud.render(renderer, camera);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    let lastPolarAngle = 0;
    let lastAzimuthalAngle = 0;

    controls.addEventListener("change", () => {
        const polarAngle = controls.getPolarAngle();
        const azimuthalAngle = controls.getAzimuthalAngle();

        const rotationDelta =
            Math.abs(polarAngle - lastPolarAngle) +
            Math.abs(azimuthalAngle - lastAzimuthalAngle);
        cloud.regress = rotationDelta > 0.0002;

        lastPolarAngle = polarAngle;
        lastAzimuthalAngle = azimuthalAngle;

        cloud.render(renderer, camera);
    });

    renderer.setAnimationLoop((time) => {
        stats.begin();
        controls.update();
        cloud.time = time / 1000;

        cloud.render(renderer, camera);
        stats.end();
    });
}

export default init;
export function isWebGLSupported() {
    try {
        let canvas = document.createElement('canvas');
        return !!(window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')));
    } catch(e) {
        return false;
    }
}